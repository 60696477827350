<template>
    <div class="container-fluid p-0">
        <section class="feature-title">
            <div style="background-image: url(https://static.vitaalumnicn.com/static/vsweb/home-bg.png);"
                 class="feature-title-background"></div>
        </section>
        <section class="vita-search">
            <div class="container p-l-50 p-r-50 pt-0 pb-0 mt-0 mb-0" style="display: flex; align-items: center;">
                <div style="width: 80%;">
                    <h1 class="title-line">{{ $t('home.whereAmbitionLives') }}</h1>
                    <div class="custom-dropdown m-t-10">
                        <div class="dropdown-form">
                            <input type="text" @focus="isFocus = true" @blur="handleBlur" v-model="keyword"
                                :placeholder="$t('home.findYourCity')">
                            <div class="search-btn"><span class="icon iconfont">&#xe660;</span></div>
                        </div>
                        <div class="dropdown-content" v-show="dropdownShow">
                            <div class="drop-item"
                                :class="index !== dropdownList.length - 1 ? 'border-bottom-gray' : ''"
                                @focus="isFocus = true"
                                @click="$goto('/cities' + item.webUrl)"
                                v-for="(item, index) in dropdownList" :key="index">{{ item.keyword }}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="qrcode" style="text-align: right; width: 20%;">
                    <img style="width: 170px; height: auto;" class="footer-img" decoding="async" loading="lazy" src="../../assets/images/qrcode_01.png">
                </div>
            </div>
        </section>
        <section
            v-if="endDate >= new Date().getTime()"
            class="section-black p-t-8 p-b-10"
            style="position: sticky; top: 0px; z-index: 11">
            <countdown-time :endDate="endDate"></countdown-time>
        </section>
        <section class="banner-img">
            <img decoding="async" src="https://static.vitaalumnicn.com/static/vsweb/home-1-1.jpg">
        </section>

        <section class="vita-cities row">
            <div class="city-left col-3">
                <div class="left-content">
                    <div class="title-line" :style="$i18n.locale === 'cn' ? { 'line-height': '1.2' } : ''">
                        {{ $t('home.startHere') }} <br>
                        {{ $t('home.goAnywhere') }}
                    </div>
                    <div class="title-note mt-1">
                        {{ $t('home.you’veFound') }} <br>
                        {{ $t('home.stayIn') }}
                    </div>
                    <div class="link mt-3 cursor" @click="$goto('/cities')">
                        <div class="view-all-cities">{{ $t('home.viewAllCities') }}</div>
                        <div class="go-btn"><span class="icon iconfont">&#xe60d;</span></div>
                    </div>
                </div>
            </div>
            <div class="col-9 p-l-45">
                <div class="city-right">
                    <div class="scroll-container custom-scroll p-b-36" ref="scrollContainer1">
                        <div class="item cursor"
                             @click="$goto('/cities/' + city.slug)"
                             v-for="(city, index) of lifeCityList"
                             :key="index">
                            <div class="item-title">
                                <span>{{ city.name }}</span>
                            </div>
                            <img decoding="async" loading="lazy" :src="city.img">
                        </div>
                        <div class="left-right-btn">
                            <div class="btn-arrow cursor icon iconfont" @click="handleOffset('left', 1)"> &#xe64c;</div>
                            <div class="btn-arrow cursor icon iconfont" @click="handleOffset('right', 1)"> &#xe64d;</div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section class="vita-video-block">
            <div class="video-header">
                <div class="video-content">
                    <span class="video-content-title">{{ $t('home.allIn') }}</span>
                    <div class="video-note">
                        <p>{{ $t('home.forStudents') }}</p>
                    </div>
                </div>
                <div class="link mt-3 cursor w-auto" @click="$goto('/life-at-vita')">
                    <div class="view-all-cities">{{ $t('home.seeWhatIncludes') }}</div>
                    <div class="go-btn"><span class="icon iconfont">&#xe60d;</span></div>
                </div>
            </div>
            <video class="p-t-90 p-b-90" title="VS Homepage" preload="none" loop width="100%" height="100%"
                   frameborder="0" muted playsinline autoplay>
                <source class="cursor" src="https://static.vitaalumnicn.com/static/vsweb/home-3-1.mp4"
                        type="video/mp4">
            </video>

        </section>

        <section class="no-settling container-fluid p-l-50">
            <div class="no-settling-left col-3">
                <div class="left-content">
					<span class="title-line">
						{{ $t('home.noSettling') }}
					</span>
                    <div class="title-note mt-1">
                        {{ $t('home.it’sYourHome') }}
                    </div>
                </div>
            </div>
            <div class="no-settling-right col-9">
                <div class="begin-big">
                    BEGIN BIG
                </div>
                <div class="scroll-container custom-scroll-2 justify-content-around" ref="scrollContainer2">
                    <div class="item" v-for="(item, index) in noSettlings" :key="index">
                        <img decoding="async" loading="lazy" :src="item.img">
                    </div>
                    <div class="left-right-btn">
                        <div class="btn-arrow cursor icon iconfont" @click="handleOffset('left', 2)"> &#xe64c;</div>
                        <div class="btn-arrow cursor icon iconfont" @click="handleOffset('right', 2)"> &#xe64d;</div>
                    </div>
                </div>

                <div class="footer-note">
                    {{ $t('home.ambitionLives') }} <br>
                    {{ $t('home.beYourBest') }}
                </div>
            </div>
        </section>

        <section class="section-white vita-hero-banner">
            <div class="content position-relative">
                <div class="position-absolute panel-1"></div>
                <div class="position-absolute panel-2">
                    <img class="img" src="https://static.vitaalumnicn.com/static/vsweb/beyondAmbition.png"
                         alt="">
                </div>
                <div class="position-absolute panel-3 text-white" style="height: 208px;padding: 30px;">
                    <div class="title font-size-50 font-weight-700 text-trade">
                        {{ $t('home.beyondAmbition') }}
                    </div>
                    <div class="font-size-14 m-t-10">
                        {{ $t('home.beyondAmbitionNote') }}
                    </div>
                </div>
                <div class="position-absolute panel-4 cursor" @click="$goto('/beyond-ambition')">
                    <div class="view-all-cities font-size-16 text-uppercase w-auto p-l-10 p-r-10 text-trade font-weight-900">
                        {{ $t('home.readMore') }}
                    </div>
                    <div class="go-btn"><span class="icon iconfont">&#xe60d;</span></div>
                </div>
            </div>
        </section>

        <social-stories></social-stories>

        <section class="w-100">
            <img class="footer-img" decoding="async" loading="lazy"
                 src="https://static.vitaalumnicn.com/static/vsweb/home-6-1.png">
        </section>

        <section class="last-vita">
            <div class="last-vita-content">
                <div class="img-content bg-last">
                    <img class="img" src="https://static.vitaalumnicn.com/static/vsweb/home-7-1.png"
                         alt="">
                </div>
                <div class="content">
                    <div class="content-text text-center">
                        <div v-html="$t('home.areYouIn')"></div>
                        <div class="link mt-3 cursor">
                            <div class="ms-auto me-auto d-flex align-items-center"  @click="$goto('/find-your-room')">
                                <div class="join-us">{{ $t('home.joinUs') }}</div>
                                <div class="go-btn icon iconfont">&#xe60d;</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

    </div>
</template>

<script>

import {getCitiesAPI, getKeywordsAPI} from '@/apis/common'
import socialStories from '@/components/social-stories'
import countdownTime from '@/components/countdownTime/countdown-time';

export default {
    name: "home",
    components: {
        socialStories,
        countdownTime
    },
    data() {
        return {
            endDate: new Date("2024-11-12T08:00:00").getTime(),
            keyword: null,
            keywords: [],
            cities: [],
            isFocus: false,
        }
    },
    created() {
        this.getCities()
        this.getKeywords()
    },
    computed: {
        dropdownList() {
            if (!this.keyword) return []
            let nameToMatch = this.keyword
            return this.keywords.filter(item => {
                const itemName = item.keyword.replace(/\s/g, "").toLowerCase(); // 移除空格并转换为小写
                const keyword = nameToMatch.replace(/\s/g, "").toLowerCase();
                return itemName.includes(keyword);
            }).slice(0, 3)
        },
        dropdownShow() {
            return this.dropdownList.length > 0 && this.isFocus
        },
        lifeCityList: function (){
            let isCn = this.$i18n.locale === 'cn'
            let arr = ['barcelona','leeds','york','coventry','nottingham','birmingham','liverpool','edinburgh','manchester','sheffield']
            const list = []
            this.cities.forEach(rest => {
                list.push({
                    id: rest.id,
                    img: rest.thumbnailUrl,
                    slug: rest.slug,
                    name: isCn ? rest.name : rest.enName,
                })
            })
            const cities = []
            arr.forEach(rest => {
                let city = list.find(item => {
                    return item.slug === rest
                })
                if (city) {
                    cities.push(city)
                }
            })
            return cities
        },
        noSettlings: function () {
            return [
                {
                    img: 'https://static.vitaalumnicn.com/static/vsweb/home-4-1.png',
                },
                {
                    img: 'https://static.vitaalumnicn.com/static/vsweb/home-4-2.png',
                },
                {
                    img: 'https://static.vitaalumnicn.com/static/vsweb/home-4-3.png',
                },
                {
                    img: 'https://static.vitaalumnicn.com/static/vsweb/home-4-4.png',
                },
                {
                    img: 'https://static.vitaalumnicn.com/static/vsweb/home-4-5.png',
                },
                {
                    img: 'https://static.vitaalumnicn.com/static/vsweb/home-4-6.png',
                },
                {
                    img: 'https://static.vitaalumnicn.com/static/vsweb/home-4-7.png',
                },
                {
                    img: 'https://static.vitaalumnicn.com/static/vsweb/home-4-8.png',
                },
                {
                    img: 'https://static.vitaalumnicn.com/static/vsweb/home-4-9.png',
                },
                {
                    img: 'https://static.vitaalumnicn.com/static/vsweb/home-4-10.png',
                },
                {
                    img: 'https://static.vitaalumnicn.com/static/vsweb/home-4-11.png',
                },
                {
                    img: 'https://static.vitaalumnicn.com/static/vsweb/home-4-12.png',
                },
                {
                    img: 'https://static.vitaalumnicn.com/static/vsweb/home-4-13.png',
                },
                {
                    img: 'https://static.vitaalumnicn.com/static/vsweb/home-4-14.png',
                },
                {
                    img: 'https://static.vitaalumnicn.com/static/vsweb/home-4-15.png',
                }
            ]
        },
    },
    methods: {
        getKeywords() {
            getKeywordsAPI().then(res => {
                this.keywords = res.data.data
            })
        },
        handleHref() {
            //window.open('https://www.vitastudent.cn/campaign/new-2022-10', '_blank');
        },
        handleBlur() {
            setTimeout(() => this.isFocus = false, 120)
        },
        async getCities() {
            if (this.$store.state.cities.length > 0) {
                this.cities = this.$store.state.cities;
            } else {
                await getCitiesAPI({showApartments: 1}).then(res => {
                    if (res.status === 200) {
                        this.cities = res.data.data
                        this.$store.commit('setCities', this.cities)
                    }
                })
            }
        },
        handleOffset(offset, type = null) {
            let scrollContainer = this.$refs['scrollContainer' + type];
            if (offset === 'left') {
                scrollContainer.scrollTo({
                    left: scrollContainer.scrollLeft - 300,
                    behavior: 'smooth' // 添加平滑滚动效果
                });
            } else {
                scrollContainer.scrollTo({
                    left: scrollContainer.scrollLeft + 300,
                    behavior: 'smooth' // 添加平滑滚动效果
                });
            }
        }
    }
}
</script>

<style lang="less" scoped>
@import "./index.less";

</style>
