<template>
    <div>
        <div class="container text-center pt-0 mt-0 pb-0 mb-0">
            <div class="time-title">
                {{ $t('common.activityName') }}
            </div>
            <div class="d-flex align-items-center justify-content-center">
                <time-card class="time-card" :newTime="newTime.days + ''" :oldTime="oldTime.days + ''" timeLable="DAYS"></time-card>
                <time-card class="time-card" :newTime="newTime.hours + ''" :oldTime="oldTime.hours + ''" timeLable="HOURS"></time-card>
                <time-card class="time-card" :newTime="newTime.minutes + ''" :oldTime="oldTime.minutes + ''" timeLable="MINUTES"></time-card>
                <time-card class="time-card" :newTime="newTime.seconds + ''" :oldTime="oldTime.seconds + ''" timeLable="SECONDS"></time-card>
            </div>
        </div>
    </div>
</template>

<script>

import timeCard from '@/components/countdownTime/time-card';
export default {
    name: 'countdown-time',
    components: {
        timeCard
    },
    data() {
        return {
            days: 0,
            hours: 0,
            minutes: 0,
            seconds: 0
        }
    },
    props: {
        endDate: {
            type: Number,
        }
    },
    created() {
        setInterval(this.refreshTime, 1000);
    },
    computed:{
        newTime(){
            let days = (Array(2).join(0) + this.days).slice(-2)
            let hours = (Array(2).join(0) + this.hours).slice(-2)
            let minutes = (Array(2).join(0) + this.minutes).slice(-2)
            let seconds = (Array(2).join(0) + this.seconds).slice(-2)
            return {
                days: days,
                hours: hours,
                minutes: minutes,
                seconds: seconds
            }
        },
        oldTime(){
            let days = (Array(2).join(0) + (this.days <= 0 ? 0 : this.days)).slice(-2)
            let hours = (Array(2).join(0) + ((this.days <= 0 && this.hours === 0) ? 0 : (this.hours === 0 ? 23 : this.hours - 1))).slice(-2)
            let minutes = (Array(2).join(0) + ((this.days <= 0 && this.hours === 0 && this.minutes === 0) ? 0 : (this.minutes === 0 ? 59 : this.minutes - 1))).slice(-2)
            let seconds = (Array(2).join(0) + ((this.days <= 0 && this.hours === 0 && this.minutes === 0 && this.seconds === 0) ? 0 : (this.seconds === 0 ? 59 : this.seconds - 1))).slice(-2)
            return {
                days: days,
                hours: hours,
                minutes: minutes,
                seconds: seconds
            }
        }
    },
    methods: {
        refreshTime() {
            const currentDate = new Date().getTime();
            const timeLeft = this.endDate - currentDate;
            this.days = Math.floor(timeLeft / (1000 * 60 * 60 * 24))
            this.hours = Math.floor((timeLeft % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
            this.minutes = Math.floor((timeLeft % (1000 * 60 * 60)) / (1000 * 60))
            this.seconds = Math.floor((timeLeft % (1000 * 60)) / 1000)
        }
    },
}
</script>

<style lang="less" scoped>
.time-title {
    margin: 0 5vw;
    white-space: normal;
    font-family: Trade Gothic LT Std Condensed,sans-serif;
    text-transform: uppercase;
    font-size: 18px;
    line-height: 25px;
}

.time-card {
    margin: 0 7.5px;
}
</style>