<template>
    <div class="time-body">
        <div class="card-container">
            <div class="card card1">
                <div class="circle" style="bottom: -5px; left: -5px"></div>
                <div class="circle" style="bottom: -5px; right: -5px"></div>
                {{ oldValue }}
            </div>
            <div class="card card2" :style="card2Style">
                <div class="circle" style="top: -5px; left: -5px"></div>
                <div class="circle" style="top: -5px; right: -5px"></div>
                {{ oldValue }}
            </div>
            <div class="card card3" :style="card3Style">
                <div class="circle" style="bottom: -5px; left: -5px"></div>
                <div class="circle" style="bottom: -5px; right: -5px"></div>
                {{ newValue }}
            </div>
            <div class="card card4">
                <div class="circle" style="top: -5px; left: -5px"></div>
                <div class="circle" style="top: -5px; right: -5px"></div>
                {{ newValue }}
            </div>
        </div>
        <div class="time-lable">{{ timeLable }}</div>
    </div>
</template>

<script>
export default {
    name: 'time-card',
    data() {
        return {
            newValue: null,
            oldValue: null,
            card2Style: {
                transform: 'rotateX(180deg)',
                transition: 'transform 0.8s' // 过渡属性
            },
            card3Style: {
                transform: 'rotateX(0deg)',
                transition: 'transform 0.8s' // 过渡属性
            },
        };
    },
    props: {
        newTime: {
            type: String,
            default: '-10'
        },
        oldTime: {
            type: String,
            default: '-10'
        },
        timeLable: {
            type: String
        }
    },
    watch: {
        newTime() {
            this.rotateCards()
        }
    },
    methods: {
        rotateCards() {
            setTimeout(() => {
                this.card2Style.transform = 'rotateX(0deg)';
                this.card2Style.transition = 'transform 0.9s';
                this.card3Style.transform = 'rotateX(-180deg)';
                this.card3Style.transition = 'transform 0.9s';
                this.resetCards();
            }, 100)
        },
        resetCards() {
            setTimeout(() => {
                this.newValue = this.newTime
                this.oldValue = this.oldTime
                this.card2Style.transform = ''
                this.card2Style.transition = 'none'
                this.card3Style.transform = ''
                this.card3Style.transition = 'none'
            },900)
        }
    }
};
</script>

<style lang="less" scoped>
* {
    margin: 0;
    padding: 0;
    //box-sizing: border-box;
}
.time-lable {
    margin-top: 15px;
    color: #fff;
    font-size: 11px;
    letter-spacing: 3px;
    text-transform: uppercase;
    font-family: Trade Gothic LT Std Condensed,sans-serif;
    margin-bottom: 0;
}
.time-body {
    text-align: center;
    color: #fff;
    padding-top: 10px;
    font-size: 40px;
    font-family: Trade Gothic LT Std Condensed,sans-serif;
}
.card-container {
    width: 77px;
    height: 86px;
    position: relative;
    perspective: 500px;
}

.card {
    position: absolute;
    width: 100%;
    height: 50%;
    left: 0;
    top: 0;
    overflow: hidden;
    background: #e91a4c;
    border: unset !important;
    color: #fff;
    line-height: 86px;

    .circle {
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background: #000;
        position: absolute;
    }
}

.card2 {
    top: 50%;
    line-height: 0;
    transform: rotateX(180deg);
    transform-origin: center top;
    backface-visibility: hidden;
    //transition: 0.5s;
    z-index: 10;
    box-shadow: 0 -2px 5px 0 rgba(71,83,115,.7) inset;
}

.card3 {
    z-index: 10;
    transform-origin: center bottom;
    //transition: 0.5s;
    backface-visibility: hidden;
}


//.card-container:hover .card2 {
//    transform: rotateX(0deg);
//}
//
//.card-container:hover .card3 {
//    transform: rotateX(-180deg);
//}

.card4 {
    top: 50%;
    line-height: 0;
    box-shadow: 0 -2px 5px 0 rgba(71,83,115,.7) inset;
}
</style>